import { PageProps } from 'gatsby';
import * as React from 'react';
import Layout from '../components/utils/Layout/Layout.component';
import { WebChatContext } from '../contexts/WebChat.context';
import Meta from '../components/utils/Meta/Meta.util';
import useStoryblok from '../hooks/useStoryblok/useStoryblok.hook';
import getMetaData from '../utils/getMeta/GetMeta.util';
import renderBloks from '../utils/renderBlocks/renderBloks';
import BreadcrumbProps from '../interfaces/Breadcrumb/Breadcrumb.interface';
import getRequiredHeaderBlok from '../utils/getRequiredHeaderBlok/getRequiredHeaderBlok.util';
import getRequiredFooterBlok from '../utils/getRequiredFooterBlok/getRequiredFooterBlok.util';
import DynamicComponent from '../components/utils/DynamicComponent/DynamicComponent.component';

interface StoryBlokPreviewPageProps {}

interface PageContext {
  breadcrumb: BreadcrumbProps;
}

const StoryBlokPreview: React.FC<
  PageProps<StoryBlokPreviewPageProps, PageContext>
> = ({ pageContext, location }) => {
  const story = useStoryblok(null, location);
  const CHAT_VISIBILITY =
    (story?.content as any)?.chat_visibility_status === 'VISIBLE';

  if (!story) {
    return (
      <div className="py-8">
        <p className="italic text-center">Loading Preview...</p>
      </div>
    );
  }

  const body = (story?.content as any)?.body;
  const blocks = renderBloks(body || []);
  const metaData = getMetaData(story?.content);

  const content = story?.content;
  const ALTERNATIVE_VERSIONS = story?.alternates;

  const FULL_SLUG = story?.full_slug;
  const URL_PARTS = FULL_SLUG.split('/');
  const NO_INDEX = (story?.content as any)?.no_index || '';
  const LANG_PREFIX = (URL_PARTS?.length >= 1 && URL_PARTS[0]) || '';

  const PAGE_SLUG = FULL_SLUG.replace(`${LANG_PREFIX}/`, '').replace(
    `${process.env.DOMAIN_FOLDER}`,
    ''
  );
  const isAlternativeNav = PAGE_SLUG === '/';

  const isInTrustCentre = (): boolean => {
    const component: string = (story?.content as any)?.component || '';
    switch (component) {
      case 'member_page':
      case 'member_page_search':
        return true;
      default:
        return false;
    }
  };

  const headerBlok = getRequiredHeaderBlok(
    isInTrustCentre() ? 'member_header' : 'header',
    LANG_PREFIX
  );
  const footerBlok = getRequiredFooterBlok(
    isInTrustCentre() ? 'member_footer' : 'footer',
    LANG_PREFIX
  );

  const HEADER = headerBlok && (
    <DynamicComponent blok={JSON.parse(headerBlok.content)} />
  );
  const FOOTER = footerBlok && (
    <DynamicComponent blok={JSON.parse(footerBlok.content)} />
  );

  return (
    <WebChatContext.Provider value={{ visibility: CHAT_VISIBILITY }}>
      <Layout
        langPrefix={LANG_PREFIX}
        isAlternativeNav={isAlternativeNav}
        breadcrumb={pageContext.breadcrumb}
        header={HEADER}
        footer={FOOTER}
      >
        {metaData && (
          <Meta
            metaData={metaData}
            url={location.href}
            canonical={(content as any).canonical_url || ''}
            noIndex={NO_INDEX}
            alternatives={ALTERNATIVE_VERSIONS}
            breadcrumb={pageContext.breadcrumb}
            pageSlug={PAGE_SLUG}
            langPrefix={LANG_PREFIX}
          />
        )}
        {blocks}
      </Layout>
    </WebChatContext.Provider>
  );
};

StoryBlokPreview.propTypes = {};

export default StoryBlokPreview;
